* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  background: url(./images/BodyBackground.svg);
}

.animatedNumber {
  color: #995ef6;
  font-size: 35px;
  font-weight: 600;
}

.slick-dots li button:before {
  margin-top: 20px;
  font-size: 16px !important;
  color: #995ef6 !important;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* .Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #1b1e22;
  z-index: 2;
  padding: 40px;
  color: white;
  border-radius: 20px;
  overflow: auto;
} */

.Overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 9, 14, 0.4);
}

.react-tabs__tab {
  cursor: pointer;
  padding: 10px;
  border-color: red;
  list-style-type: none;
  font-weight: 600;
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #2d3033;
  margin-bottom: 20px;
}

.react-tabs__tab--selected {
  color: #995ef6;
  border-bottom: 3px solid #995ef6;
}
